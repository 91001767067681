.main[data-v-64ebcd68] {
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
}
.main .title-text[data-v-64ebcd68] {
  margin: 50px auto 9px;
}
.claim-mask[data-v-64ebcd68] {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 15px;
  background-color: #00000080;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 101;
}
