* {
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
}
body,
p,
ul,
ol {
  padding: 0;
  margin: 0;
}
#app {
  font-family: Helvetica, Arial, PingFang SC, Microsoft yahei, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}
#app .ant-spin-nested-loading,
#app .ant-spin-container {
  height: 100%;
}
#app .ant-spin-nested-loading.ant-spin-loading-custom-class .ant-spin-container::after {
  height: calc(100vh);
}
#app .ant-spin-blur {
  min-height: calc(100vh);
}
.font-10-FFF {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: #FFFFFF;
}
.font-10-bold-FFF {
  font-style: normal;
  font-weight: 610;
  font-size: 10px;
  line-height: 12px;
  color: #FFFFFF;
}
.font-10-bold-FB3A3A {
  font-style: normal;
  font-weight: 610;
  font-size: 10px;
  line-height: 12px;
  color: #FB3A3A;
}
.font-10-bold-F23F3F {
  font-style: normal;
  font-weight: 610;
  font-size: 10px;
  line-height: 12px;
  color: #F23F3F;
}
.font-12-000 {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
}
.font-12-999 {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #999999;
}
.font-12-666 {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #666666;
}
.font-12-FFF {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #FFFFFF;
}
.font-12-333 {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #333333;
}
.font-12-EFEFEF {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #EFEFEF;
}
.font-12-34CC7B {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #34CC7B;
}
.font-12-FB3A3A {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #FB3A3A;
}
.font-12-EC6937 {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #EC6937;
}
.font-12-CACACA {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #CACACA;
}
.font-12-bold-000 {
  font-style: normal;
  font-weight: 610;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
}
.font-12-bold-333 {
  font-style: normal;
  font-weight: 610;
  font-size: 12px;
  line-height: 14px;
  color: #333333;
}
.font-12-bold-EC6937 {
  font-style: normal;
  font-weight: 610;
  font-size: 12px;
  line-height: 14px;
  color: #EC6937;
}
.font-12-bold-34CC7B {
  font-style: normal;
  font-weight: 610;
  font-size: 12px;
  line-height: 14px;
  color: #34CC7B;
}
.font-12-bold-FB3A3A {
  font-style: normal;
  font-weight: 610;
  font-size: 12px;
  line-height: 14px;
  color: #FB3A3A;
}
.font-13-666 {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #666666;
}
.font-14-FFF {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
}
.font-14-000 {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}
.font-14-666 {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #666666;
}
.font-14-333 {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #333333;
}
.font-14-999 {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #999999;
}
.font-14-34CC7B {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #34CC7B;
}
.font-14-EC6937 {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #EC6937;
}
.font-14-00AAEF {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #00AAEF;
}
.font-14-bold-000 {
  font-style: normal;
  font-weight: 610;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}
.font-14-bold-333 {
  font-style: normal;
  font-weight: 610;
  font-size: 14px;
  line-height: 17px;
  color: #333333;
}
.font-14-bold-666 {
  font-style: normal;
  font-weight: 610;
  font-size: 14px;
  line-height: 17px;
  color: #666666;
}
.font-14-bold-FFF {
  font-style: normal;
  font-weight: 610;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
}
.font-14-bold-EFEFEF {
  font-style: normal;
  font-weight: 610;
  font-size: 14px;
  line-height: 17px;
  color: #EFEFEF;
}
.font-14-bold-00AAEF {
  font-style: normal;
  font-weight: 610;
  font-size: 14px;
  line-height: 17px;
  color: #00AAEF;
}
.font-14-bold-FB3A3A {
  font-style: normal;
  font-weight: 610;
  font-size: 14px;
  line-height: 17px;
  color: #FB3A3A;
}
.font-14-bold-EC6937 {
  font-style: normal;
  font-weight: 610;
  font-size: 14px;
  line-height: 17px;
  color: #EC6937;
}
.font-16-000 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}
.font-16-333 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
}
.font-16-EC6937 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #EC6937;
}
.font-16-FB3A3A {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #FB3A3A;
}
.font-16-CACACA {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #CACACA;
}
.font-16-666 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #666666;
}
.font-16-999 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #999999;
}
.font-16-FFF {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
}
.font-18-333 {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #333333;
}
.font-16-bold-000 {
  font-style: normal;
  font-weight: 610;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}
.font-16-bold-FFF {
  font-style: normal;
  font-weight: 610;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
}
.font-16-bold-333 {
  font-style: normal;
  font-weight: 610;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
}
.font-16-bold-00AAEF {
  font-style: normal;
  font-weight: 610;
  font-size: 16px;
  line-height: 19px;
  color: #00AAEF;
}
.font-16-bold-FB3A3A {
  font-style: normal;
  font-weight: 610;
  font-size: 16px;
  line-height: 19px;
  color: #FB3A3A;
}
.font-16-bold-EC6937 {
  font-style: normal;
  font-weight: 610;
  font-size: 16px;
  line-height: 19px;
  color: #EC6937;
}
.font-18-000 {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
}
.font-18-FFF {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #FFFFFF;
}
.font-18-bold-000 {
  font-style: normal;
  font-weight: 610;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
}
.font-18-bold-333 {
  font-style: normal;
  font-weight: 610;
  font-size: 18px;
  line-height: 21px;
  color: #333333;
}
.font-18-bold-FFF {
  font-style: normal;
  font-weight: 610;
  font-size: 18px;
  line-height: 21px;
  color: #FFFFFF;
}
.font-20-bold-FFF {
  font-style: normal;
  font-weight: 610;
  font-size: 20px;
  line-height: 24px;
  color: #FFFFFF;
}
.font-20-bold-000 {
  font-style: normal;
  font-weight: 610;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}
.font-20-bold-333 {
  font-style: normal;
  font-weight: 610;
  font-size: 20px;
  line-height: 24px;
  color: #333333;
}
.font-20-bold-34cc7b {
  font-style: normal;
  font-weight: 610;
  font-size: 20px;
  line-height: 24px;
  color: #34CC7B;
}
.font-24-bold-000 {
  font-style: normal;
  font-weight: 610;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
}
.font-24-bold-333 {
  font-style: normal;
  font-weight: 610;
  font-size: 24px;
  line-height: 29px;
  color: #333333;
}
.font-24-bold-EC6937 {
  font-style: normal;
  font-weight: 610;
  font-size: 24px;
  line-height: 29px;
  color: #EC6937;
}
.font-24-bold-FB3A3A {
  font-style: normal;
  font-weight: 610;
  font-size: 24px;
  line-height: 29px;
  color: #FB3A3A;
}
.font-24-bold-F23F3F {
  font-style: normal;
  font-weight: 610;
  font-size: 24px;
  line-height: 29px;
  color: #F23F3F;
}
.font-24-bold-FFF {
  font-style: normal;
  font-weight: 610;
  font-size: 24px;
  line-height: 29px;
  color: #FFFFFF;
}
.font-24-bold-34CC7B {
  font-style: normal;
  font-weight: 610;
  font-size: 24px;
  line-height: 29px;
  color: #34CC7B;
}
.font-34-bold-000 {
  font-style: normal;
  font-weight: 610;
  font-size: 34px;
  line-height: 41px;
  color: #000000;
}
.font-34-bold-FFF {
  font-style: normal;
  font-weight: 610;
  font-size: 34px;
  line-height: 41px;
  color: #FFFFFF;
}
.font-34-bold-FB3A3A {
  font-style: normal;
  font-weight: 610;
  font-size: 34px;
  line-height: 41px;
  color: #FB3A3A;
}
.font-50-bold-FFF {
  font-style: normal;
  font-weight: 610;
  font-size: 50px;
  line-height: 60px;
  color: #FFFFFF;
}
.line-word-ellipsis-1 {
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.line-word-ellipsis-2 {
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
.ant-btn:not(.ant-btn-lg),
.ant-btn:not(.ant-btn-sm) {
  font-size: 16px;
}
.ant-btn.ant-btn-primary,
.ant-btn.ant-btn-primary:hover,
.ant-btn.ant-btn-primary:focus,
.ant-btn.ant-btn-primary:active {
  background-color: #EC6937;
  border: none;
}
.ant-btn.ant-btn-primary:disabled,
.ant-btn.ant-btn-primary:disabled:hover,
.ant-btn.ant-btn-primary:disabled:focus,
.ant-btn.ant-btn-primary:disabled:active {
  background-color: rgba(236, 105, 55, 0.5);
  color: #FFFFFF;
}
.ant-btn.ant-btn-default,
.ant-btn.ant-btn-default:hover,
.ant-btn.ant-btn-default:focus,
.ant-btn.ant-btn-default:active {
  background-color: #FDF0EA;
  border: none;
  color: #EC6937;
}
.ant-btn.ant-btn-default:disabled,
.ant-btn.ant-btn-default:disabled:hover,
.ant-btn.ant-btn-default:disabled:focus,
.ant-btn.ant-btn-default:disabled:active {
  background-color: rgba(253, 240, 234, 0.5);
  border: none;
}
.ant-btn.ant-btn-dashed,
.ant-btn.ant-btn-dashed:hover,
.ant-btn.ant-btn-dashed:focus,
.ant-btn.ant-btn-dashed:active {
  background-color: #EFEFEF;
  border: none;
  color: #333333;
}
.ant-btn.ant-btn-dashed:disabled,
.ant-btn.ant-btn-dashed:disabled:hover,
.ant-btn.ant-btn-dashed:disabled:focus,
.ant-btn.ant-btn-dashed:disabled:active {
  background-color: rgba(239, 239, 239, 0.5);
  border: none;
}
.margin-top-3 {
  margin-top: 3px;
}
.margin-right-3 {
  margin-right: 3px;
}
.margin-top-4 {
  margin-top: 4px;
}
.margin-left-4 {
  margin-left: 4px;
}
.margin-right-4 {
  margin-right: 4px;
}
.margin-bottom-4 {
  margin-bottom: 4px;
}
.margin-bottom-5 {
  margin-bottom: 5px;
}
.margin-top-5 {
  margin-top: 5px;
}
.margin-top-6 {
  margin-top: 6px;
}
.margin-left-6 {
  margin-left: 6px;
}
.margin-right-6 {
  margin-right: 6px;
}
.margin-left-8 {
  margin-left: 8px;
}
.margin-right-8 {
  margin-right: 8px;
}
.margin-left-10 {
  margin-left: 10px;
}
.margin-top-10 {
  margin-top: 10px;
}
.margin-top-12 {
  margin-top: 12px;
}
.margin-left-12 {
  margin-left: 12px;
}
.margin-bottom-12 {
  margin-bottom: 12px;
}
.margin-right-10 {
  margin-right: 10px;
}
.margin-left-15 {
  margin-left: 15px;
}
.margin-right-15 {
  margin-right: 15px;
}
.margin-top-15 {
  margin-top: 15px;
}
.margin-top-20 {
  margin-top: 20px;
}
.margin-left-20 {
  margin-left: 20px;
}
.margin-top-24 {
  margin-top: 24px;
}
.margin-bottom-24 {
  margin-bottom: 24px;
}
.margin-left-24 {
  margin-left: 24px;
}
.margin-top-negative-44 {
  margin-top: -44px;
}
.padding-left-right-15 {
  padding-left: 15px;
  padding-right: 15px;
}
.padding-left-10 {
  padding-left: 10px;
}
.padding-top-10 {
  padding-top: 10px;
}
.padding-bottom-10 {
  padding-bottom: 10px;
}
.padding-top-12 {
  padding-top: 12px;
}
.padding-right-15 {
  padding-right: 15px;
}
.padding-top-24 {
  padding-top: 24px;
}
.padding-bottom-24 {
  padding-bottom: 24px;
}
.padding-bottom-90 {
  padding-bottom: 90px;
}
.padding-left-right-30 {
  padding-left: 30px;
  padding-right: 30px;
}
.ant-message-notice {
  color: #333333;
}
.ant-message-notice .ant-message-notice-content {
  border-radius: 12px;
}
.ant-message-notice .ant-message-notice-content .ant-message-custom-content {
  display: flex;
  align-items: center;
}
.ant-message-notice .ant-message-notice-content .ant-message-custom-content > span:last-child {
  word-break: break-all;
}
.flex-vertical-center {
  display: flex;
  align-items: center;
}
.inline-flex-vertical-center {
  display: inline-flex;
  align-items: center;
}
.ant-modal.ant-modal-confirm.ant-modal-confirm-warning.ant-modal-warning .ant-modal-content {
  border-radius: 12px;
}
.ant-modal.ant-modal-confirm.ant-modal-confirm-warning.ant-modal-warning .ant-modal-content .ant-modal-body {
  border-radius: 12px;
  padding: 24px 20px;
}
.ant-modal.ant-modal-confirm.ant-modal-confirm-warning.ant-modal-warning .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .anticon.anticon-exclamation-circle {
  display: none;
}
.ant-modal.ant-modal-confirm.ant-modal-confirm-warning.ant-modal-warning .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-title {
  font-size: 18px;
  line-height: 21px;
  font-weight: 601;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-modal.ant-modal-confirm.ant-modal-confirm-warning.ant-modal-warning .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-content {
  margin-left: 0;
  margin-top: 24px;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
  text-align: center;
}
.ant-modal.ant-modal-confirm.ant-modal-confirm-warning.ant-modal-warning .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-btns {
  width: 100%;
}
.ant-modal.ant-modal-confirm.ant-modal-confirm-warning.ant-modal-warning .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-btns .ant-btn.ant-btn-primary {
  width: 100%;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
}
